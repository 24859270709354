/* TEXT */
.home-page__product-description__text-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  text-justify: inter-word;
  height: 100%;
  color: var(--scroll-down-content-text-color);
  font-family: var(--scroll-down-content-text-font);
}

.home-page__product-description__text-text {
  color: var(--scroll-down-content-text-color);
  font-family: var(--scroll-down-content-text-font);
  margin: 0;
}

/* PARAGRAPH */
.home-page__product-description__individual-container section {
  display: flex;
  flex-direction: row;
}

.home-page__product-description__paragraph {
  color: var(--product-description-text-color);
  font-family: var(--product-description-text-font);
}

.home-page__product-description__paragraph-text {
}

.home-page__product-description__text-arrow {
  height: 30px;
  width: auto;
  padding-bottom: 3px;
  margin-top: 0px;
  margin-bottom: auto;
  margin-right: 10px;
}

/* MOBILE */
@media only screen and (min-width: 320px) and (max-width: 991px) {
  /* TEXT */
  .home-page__product-description__text-container {
    width: 90%;
    margin-bottom: 10vw;
  }

  .home-page__product-description__text-text {
    font-size: 1rem;
    text-align: left;
    text-justify: inter-word;
  }

  /* PARAGRAPH */
  .home-page__product-description__individual-container section {
    flex-direction: column;
  }

  .home-page__product-description__image {
    width: 60%;
    margin-top: 5vw;
    margin-bottom: 5vw;
  }

  .home-page__product-description__paragraph {
    /* width: 80%; */
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3vh;
    margin-bottom: 2vh;
    font-size: 1rem;
    /* decide this */
    text-align: justify;
    text-justify: inter-word;
  }
}

/* DESKTOP */
@media only screen and (min-width: 992px) {
  .home-page__product-description__container {
    margin-bottom: 20vh;
  }

  /* TEXT */
  .home-page__product-description__text-container {
    width: 60%;
  }

  .home-page__product-description__text-text {
    font-size: 21px;
    text-align: left;
    text-justify: inter-word;
  }

  /* PARAGRAPH */
  .home-page__product-description__ls-container {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-page__product-description__individual-container {
    width: 60%;
    margin-bottom: 2rem;
  }

  .home-page__product-description__individual-container section {
    flex-direction: row;
  }

  .home-page__product-description__image-container {
    width: 20%;
    flex: 0 0 20%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5%;
  }

  .home-page__product-description__image {
    width: 100%;
    height: auto;
  }

  .home-page__product-description__paragraph {
    font-size: 18.5px;
    line-height: 28px;
    text-align: left;
    text-justify: inter-word;
    margin-top: auto;
    margin-bottom: auto;
  }

  .home-page__product-description__paragraph-text {
    margin-bottom: 0;
  }

  #home-page__product-description__individual-container-0 {
    margin-top: 5rem;
    margin-left: 0%;
  }

  #home-page__product-description__individual-container-1 {
    margin-top: 5rem;
    margin-left: 20%;
  }

  #home-page__product-description__individual-container-2 {
    margin-top: 5rem;
    margin-left: 40%;
  }
}
