.footer__container {
  height: fit-content;
  padding-top: 1.5rem;
  padding-bottom: 0rem;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  z-index: 40;
  position: absolute;
  background-color: var(--footer-background-color);
}

.footer__logo-container {
  max-width: 100%;
}

.footer__link-icon {
  color: var(--footer-icon-color) !important;
  font-size: 2em;
}

.footer__link-icon:hover {
  color: var(--footer-icon-hover-color) !important;
}

.footer__link {
  text-decoration: none;
  color: inherit;
}

.footer__links_container {
  margin-top: auto;
  margin-bottom: auto;
}

.footer__internal_links_container {
  margin: auto;
}

.footer__internal_links_container p {
  margin: auto;
  margin-bottom: 16px ;
}

.footer__internal_links_container p:last-child {
  margin: auto;
}

.footer__rights {
  margin-top: 1rem;
}

/* phone */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer__logo-container img {
    max-width: 40%;
    /* height: 100%; */
    margin: auto;
  }
  .footer__internal_links_container {
    display: none;
  }
}

/* Ipad and or Computer */
@media screen and (min-width: 768px) {
  .footer__grid {
    display: grid;
    grid-template-columns: repeat(6, calc(100%/6));
    grid-gap: 0px;
  }

  .footer__logo-container img {
    max-width: 70%;
    height: 100%;
    margin: auto;
  }
}