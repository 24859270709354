.product-page__filter__container {
  height: 100%;
  width: 60%;
  margin: 0 auto;
}

.product-page__filter__wrap {
  height: fit-content;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.product-page__filter__option {
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.product-page__filter__icon {
  margin-right: 10px;
}

.product-page__filter__button:focus,
.product-page__filter__button:active {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .product-page__filter__container {
    width: 96vw;
    margin: 0 auto;
  }
  .product-page__filter__wrap {
    width: 100%;
  }
  .product-page__filter__option {
    width: 100%;
  }

  .product-page__filter__button {
    display: flex;
    flex-direction: column;
    width: 24vw; /* container width / amount of buttons (4) */
    font-size: var(--product-page-filter-font-size);
    font-family: var(--product-page-filter-font);
  }

  .product-page__filter__desktop {
    display: none;
  }
}

@media screen and (min-width: 992px) {
}
