.forgot-password-logo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.forgot-password-container {
  width: 33%;
  background-color: aliceblue;
  margin-left: 33vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
  border-radius: 10px;
  text-align: center;
  color: black;
}
