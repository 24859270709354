.product-page__product-info__container {
  height: 100%;
  padding: 20px;
  background-color: var(--product-page-info-background-color);
  border-radius: var(--product-page-info-border-radius);
  font: var(--product-page-info-text-font);
  color: var(--product-page-info-text-color);
}

.product-page__product-info__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 0.8rem;
}

.product-page__product-info__table th {
  text-align: left;
}

.product-page__product-info__table td {
  text-align: right;
}

.product-page__product-info__table-title th {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 15px 0;
  text-align: center;
}

.product-page__product-info__table-title img {
  width: 70px;
}

.product-page__product-info__table-subtitle th {
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 10px;
}

.table__add-bottom-line th,
.table__add-bottom-line td {
  border-bottom: 1px solid var(--product-page-info-border-color);
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .product-page__product-info__container {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .product-page__product-info__container {
    width: calc(100%-90px);  /* 100% - 2 * margin */
    margin: 0 45px;
  }
}
