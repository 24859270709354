.register-container {
  /* height: 50vh; */
  width: 33%;
  background-color: aliceblue;
  margin-left: 33vw;
  margin-bottom: 10vh;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 5vw;
  padding-right: 5vw;
  border-radius: 10px;
  text-align: center;
  color: black;
}