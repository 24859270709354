.home-page__projects__swiper__image img {
  width: 90%;
  object-fit: cover;
}

.home-page__projects__swiper div {
  height: fit-content;
}

.home-page__projects__swiper .swiper-button-next,
.home-page__projects__swiper .swiper-button-prev {
  background-color: var(--home-page-projects-swiper-button-color);
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.home-page__projects__swiper .swiper-button-next:after,
.home-page__projects__swiper .swiper-button-prev:after {
  font-size: 10px !important;
  color: white;
}

.home-page__projects__swiper .swiper-button-next {
  left: calc(50% - 20px + 80px);
  top: 95%;
}

.home-page__projects__swiper .swiper-button-prev {
  left: calc(50% - 20px - 80px);
  top: 95%;
}

@media only screen and (min-width: 320px) {
  .home-page__projects__swiper__image img {
    height: 130vw;
  }
}

@media screen and (min-width: 768px) {
  .home-page__projects__swiper__image img {
    height: 70vw;
  }
}

@media screen and (max-width: 991px) {
  .home-page__projects__swiper .swiper-button-next,
  .home-page__projects__swiper .swiper-button-prev {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .home-page__projects__swiper__image img {
    height: 24vw;
  }
}
