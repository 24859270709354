.home-page__projects-listing__container {
  margin-top: 10vh;
  /* margin-bottom: 10vh; */
}

.home-page__projects-listing__projects-carrousel {
  height: 100%;
}

.home-page__projects-listing__title {
  font-family: var(--projects-title-font);
  color: var(--projects-title-color);
}

.home-page__projects-listing__title h1 {
  font-size: 4rem;
  margin: 0;
  padding: 0;
}

.home-page__projects-listing__subtitle {
  font-family: var(--projects-subtitle-font);
  color: var(--projects-subtitle-color);
}

.home-page__projects-listing__subtitle h2 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 320px) {
  .home-page__projects-listing__container {
    height: 170vw;
  }
  .home-page__projects-listing__text-container {
    margin-bottom: 5vh;
  }
}

@media screen and (min-width: 768px) {
  .home-page__projects-listing__container {
    height: 100vw;
  }
}

@media only screen and (min-width: 992px) {
  .home-page__projects-listing__text-container {
    width: 25%;
    height: 100%;
    float: left;
    text-align: left;
    transform: rotate(-90deg) translate(100%, 100%);
    transform-origin: right bottom;
    margin-bottom: 0;
  }

  .home-page__projects-listing__text {
    transform: translate(4vw, -100%);
    transform-origin: right bottom;
  }

  .home-page__projects-listing__projects-carrousel {
    width: 75%;
    float: right;
  }

  .home-page__projects-listing__container {
    height: 28vw;
  }
}
