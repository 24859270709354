.home-page__intro-container__title img {
  width: 100%;
  height: auto;
}

.home-page__intro-container section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;
}

.home-page__intro-container__image-container {
  width: 100%;
  height: auto;
  text-align: right;
  margin-top: 5vh;
  margin-bottom: 5vh;
  overflow: hidden;
}

.home-page__intro-container__image-container img {
  width: 100%;
  height: auto;
}

.home-page__intro-container__text-container {
  color: var(--scroll-down-content-text-color);
  font-family: var(--scroll-down-content-text-font);
  margin-top: auto;
  margin-bottom: auto;
}

.home-page__intro-container__text-container h1 {
  color: var(---scroll-down-content-title-color);
  font-family: var(--scroll-down-content-title-font);
}

.home-page__intro-container__text-text {
  color: var(--scroll-down-content-text-color);
  font-family: var(--scroll-down-content-text-font);
  margin: 0;
}

.home-page__text-arrow {
  height: 30px;
  width: auto;
  padding-bottom: 3px;
  margin-top: 0px;
  margin-bottom: auto;
  margin-right: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .home-page__intro-container__title {
    padding-top: 1rem;
    width: 80%;
    margin: 5rem auto;
  }

  .home-page__intro-container__text-container {
    text-align: left;
    text-justify: inter-word;
    padding-left: 5%;
    padding-right: 5%;
  }

  .home-page__intro-container__text-text {
    font-size: 1.1rem;
  }

  .home-page__intro-container__image-container img {
    width: 145%;
  }
}

@media only screen and (min-width: 992px) {
  .home-page__intro-container__title {
    width: 20%;
    margin-top: none;
    margin-left: auto;
    margin-right: auto;
  }

  .home-page__intro-container section {
    flex-direction: row;
    margin-bottom: 5vh;
  }

  .home-page__intro-container__text-container {
    text-align: left;
    text-justify: inter-word;
    padding-left: 3%;
    padding-right: 3%;
    width: 55rem;
    flex: 0 0 55rem;
  }

  .home-page__intro-container__text-text {
    font-size: 21px;
  }

  .home-page__intro-container__image-container img {
    width: 100%;
  }
}
