.product-page__product-images__wrapper {
  display: flex;
}

.product-page__product-images__image {
  width: calc((45vw - 90px) / 4);
  height: calc(10vw - 10px);
  overflow: hidden;
  margin: 5px 5px 5px 5px;
  border-radius: 10px;
  align-self: center;
  align-items: center;
}

#product-page__product-images__image-big {
  margin: 0 5px 5px 5px;
  width: calc(45vw - 90px); /* total width - margins */
  height: 30vw;
}

.product-page__product-images__image img {
  max-height: calc((45vw - 90px) / 4);
  align-items: center;
}

#product-page__product-images__image-big img {
  max-height: 35vw;
  align-items: center;
}

.product-page__product-images__image-mobile {
  width: calc(90vw / 2 - 2 * var(--product-page-images-margin-mobile));
  height: calc(90vw / 2 - 2 * var(--product-page-images-margin-mobile));
  overflow: hidden;
  margin-left: var(--product-page-images-margin-mobile);
  margin-right: var(--product-page-images-margin-mobile);
  margin-bottom: calc(2 * var(--product-page-images-margin-mobile));
  border-radius: 10px;
  align-self: center;
  align-items: center;
}

.product-page__product-images__image-mobile img {
  max-height: calc(90vw / 2 - 2 * var(--product-page-images-margin-mobile));
  align-items: center;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .product-page__product-images__container {
    width: 100%;
    height: auto;
  }

  .product-page__product-images__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: var(--product-page-images-margin-mobile); */
  }

  .product-page__product-images__wrapper div {
    columns: 2;
    gap: 0px;
    /* padding: var(--product-page-images-margin-mobile); */
  }
}

@media only screen and (min-width: 992px) {
  .product-page__product-images__container {
    width: 45vw;
    height: 40vw;
    overflow: hidden;
    margin: 0 40px 50px 40px;
  }
}
