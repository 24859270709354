.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background: var(--login-modal-overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: block;
  background: var(--login-modal-background-color);
  border-radius: 1rem;
  overflow: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .modal-box {
    width: 90vw;
    height: 70vh;
  }
}

@media screen and (min-width: 992px) {
  .modal-box {
    width: 30%;
    height: auto;
  }
}
