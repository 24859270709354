.home-page__final-slogan__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.home-page__final-slogan__title {
  color: var(--final-slogan-title-color);
  font-family: var(--final-slogan-title-font);
  width: -moz-fit-content;
  width: fit-content;
}

.home-page__final-slogan__container img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.home-page__final-slogan__text {
  font-size: 2rem;
  color: var(--final-slogan-text-color);
  font-family: var(--final-slogan-text-font);
}

/* Mobile */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .home-page__final-slogan__text__container {
    width: 65vw;
  }

  .home-page__final-slogan__container img {
    margin-bottom: 2vh;
  }

  .home-page__final-slogan__title {
    font-size: 6vw;
  }
}

@media screen and (min-width: 768px) {
  .home-page__final-slogan__text__container {
    /* width: 65vh; */
    width: 20%;
  }

  .home-page__final-slogan__container img {
    margin-bottom: 5vh;
  }

  .home-page__final-slogan__title {
    font-size: 6vh;
  }
}
