.login-modal__tablist {
  color: var(--login-modal-tablist-text-color);
  font-family: var(--login-modal-tablist-text-font);
  font-size: 1.5rem;
  background-color: var(--login-modal-tablist-background-color);
  width: 100%;
}

.login-modal__tablist div div button {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--login-modal-tablist-text-font) !important;
  height: 100%;
}

.MuiTabs-indicator {
  background-color: var(--login-modal-tablist-indicator-color) !important;
}

.login-modal__container {
  color: black;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .login-modal__tablist div div button {
    font-size: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .login-modal__tablist div div button {
    font-size: 1.5rem;
  }
}
