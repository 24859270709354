.product-page__filter-price__container {
  width: 96vw;
}

.product-page__filter__option ul.dropdown-menu {
  /* background-color: red; */
  width: 96vw;
  margin-left: 2vw;
  margin-right: 2vw;
  position: fixed !important;
  left: 2vw !important;
  top: 20vh !important;
  height: 60vh !important;
  transform: none !important;
}