.login-modal__login__container {
  width: 80%;
  margin: auto;
  text-align: center;
  color: black;
}

.login-modal__error-message__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  color: red;
  text-shadow: 1px 1px 2px black;
  background-color: #f49c9c;
  border-radius: 10px;
  margin: 1rem 0;
  border: red 2px solid;
}

.login-modal__login__button {
  background-color: var(
    --login-modal-login-form-button-background-color
  ) !important;
  color: var(--login-modal-login-form-button-text-color) !important;
  font-family: var(--login-modal-login-form-button-text-font) !important;
  border: none;
  width: 100%;
  border-radius: 20px;
}

.login-modal__login__button:hover {
  background-color: var(
    --login-modal-login-form-button-hover-background-color
  ) !important;
  color: var(--login-modal-login-form-button-hover-text-color) !important;
}

.login-modal__login__icon {
  color: var(--login-modal-login-form-icon-color) !important;
}

.login-modal__login__icon:hover {
  color: var(--login-modal-login-form-icon-hover-color) !important;
}

.login-modal__forgot-password__container {
  text-align: left;
}

.login-modal__forgot-password__container a {
  text-decoration: none;
  color: var(--login-modal-forgot-password-text-color);
}

.login-modal__forgot-password__container a:hover {
  text-decoration: underline !important;
}
