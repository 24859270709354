.navbar__container {
  border-style: solid;
  border-left-width: var(--border-size);
  border-right-width: var(--border-size);
  border-bottom: none;
  border-top: none;
  margin-top: calc(3 * var(--border-size));
  width: 100%;
  background-color: var(--navbar-background-color);
  border-top: var(--border-size) solid var(--border-color);
  border-right: var(--border-size) solid var(--border-color);
  border-left: var(--border-size) solid var(--border-color);
  padding-left: 5%;
  padding-right: 5%;
  padding-top: var(--navbar-padding-top);
}

.navbar__horizontal-ul li {
  font-family: var(--navbar-text-font);
  color: var(--navbar-text-color);
}

.navbar__item__link,
.navbar__item__link:visited,
.navbar__item__link:hover,
.navbar__item__link:active {
  text-decoration: none;
  color: inherit;
  display: block;
  margin: 0 auto;
}

.navbar__item__button {
  border: none !important;
  background-color: transparent !important;
}

.navbar__logo {
  margin: 0.5rem 0;
  width: auto;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .navbar {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 40;
    padding: 0;
  }

  .navbar__item {
    align-items: left;
    justify-content: left;
    text-align: left;
    padding: 10px 0;
  }

  .navbar__item:active,
  .navbar__item:visited {
    background-color: var(--navbar-hover-color);
  }

  .navbar__logo {
    height: calc(var(--navbar-height-mobile) * 0.8);
  }
}

@media only screen and (min-width: 992px) {
  .navbar__item {
    list-style: none;
    font-size: 15.5px;
    font-weight: 400;
    text-align: right;
    padding: 5px 20px;
    border-radius: 20px;
  }

  .navbar__item:hover {
    background-color: var(--navbar-hover-color);
  }

  .navbar__logo {
    height: calc(var(--navbar-height) * 0.8);
  }

  .navbar__show {
    height: calc(var(--navbar-height) + var(--navbar-padding-top));
    width: 100%;
    position: fixed;
    top: 0px;
    transition: 0.3s linear;
    display: flex;
    justify-content: stretch;
    align-items: center;
    z-index: 40;
    top: calc(var(--border-size) * -1);
  }

  .navbar__hidden {
    height: calc(var(--navbar-height) + var(--navbar-padding-top));
    width: 100%;
    z-index: 40;
    position: fixed;
    top: -8rem;
    transition: 0.3s linear;
  }
}
