.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.product-page__products__loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
}

.product-page__products__loading-spacer {
  height: calc(85vh - var(--navbar-height));
  width: 100%;
}

.product-page__products__swiper .swiper-button-next {
  /* full screen minus extra borders (proyect wrap width is 75%) */
  left: calc(100vw - 12.5vw);
  top: 50%;
}

.product-page__products__swiper .swiper-button-prev {
  /* extra borders (proyect wrap width is 75%) minus size of button (extracted from navigation.css in swiper package) */
  left: calc(12.5vw - var(--swiper-navigation-size) / 44 * 27);
  top: 50%;
}

@media screen and (max-width: 991px) {
  .product-page__products__swiper .swiper-button-next,
  .product-page__products__swiper .swiper-button-prev {
    display: none;
  }

  .product-page__products__loading-spacer {
    height: calc(85vh - var(--navbar-height-mobile));
  }
}
