.product-page__container {
  padding-top: var(--navbar-height);
  background-color: var(--product-page-background-color);
  /* background-color: yellowgreen; */
}

.product-page__sub-container {
  display: flex;
  width: 100%;
}

.product-page__blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.product-page__coming-soon-image-container {
  left: 0;
  top: 0;
  width: 100vw;
  height: auto;
  /* margin: auto 0; */
  position: absolute;
  z-index: 2;
}

.product-page__coming-soon-image-container img {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .product-page__container {
    padding-top: var(--navbar-height-mobile);
  }
}