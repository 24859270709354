@font-face {
  font-family: Futura;
  src: url(assets/fonts/Futura.ttc);
}

@font-face {
  font-family: FuturaLT;
  src: url(assets/fonts/FuturaLT.ttf);
}

@font-face {
  font-family: FuturaLT-Bold;
  src: url(assets/fonts/FuturaLT-Bold.ttf);
}


@font-face {
  font-family: FuturaLT-Book;
  src: url(assets/fonts/FuturaLT-Book.ttf);
}

@font-face {
  font-family: MuseoSans-100;
  src: url(assets/fonts/MuseoSans-100.otf);
}

@font-face {
  font-family: MuseoSans-300;
  src: url(assets/fonts/MuseoSans-300.otf);
}

@font-face {
  font-family: MuseoSans_500;
  src: url(assets/fonts/MuseoSans_500.otf);
}

@font-face {
  font-family: MuseoSans_700;
  src: url(assets/fonts/MuseoSans_700.otf);
}
/* OLD FONT SHOULD REMOVE */
@font-face {
  font-family: QBDKCB-FuturaLT-Book;
  src: url(assets/fonts/QBDKCB-FuturaLT-Book.ttf);
}

:root {
  /* Global */
  --border-color: rgb(244, 244, 244);

  /* Home Page - Landing Page */
  --landing-page-background-color: rgb(244, 244, 244);
  --landing-page-text-color: rgb(44, 59, 177);
  --landing-page-text-font: "FuturaLT", sans-serif;
  --landing-page-button-text-color: rgb(244, 244, 244);
  --landing-page-button-text-font: "FuturaLT-Book", sans-serif;
  --landing-page-button-background-color: rgb(44, 59, 177);

  /* Home Page - Scroll Down Content */
  --scroll-down-content-text-font: "MuseoSans_500", sans-serif;
  --scroll-down-content-text-color: rgb(26, 23, 27);
  --scroll-down-content-title-font: "MuseoSans_500", sans-serif;
  --scroll-down-content-title-color: rgb(26, 23, 27);

  /* Home Page - Intro */
  --intro-title-color: rgb(44, 59, 177);
  --intro-title-font: "Futura", sans-serif;

  /* Home Page - Projects */
  --projects-title-font: "MuseoSans_700", sans-serif;
  --projects-title-color: rgb(26, 23, 27);
  --projects-subtitle-font: "MuseoSans_700", sans-serif;
  --projects-subtitle-color: rgb(84, 130, 255);
  --projects-carousel-id-font: "MuseoSans_500", sans-serif;
  --projects-carousel-id-color: rgb(26, 23, 27);
  --projects-carousel-commune-font: "MuseoSans_500", sans-serif;
  --projects-carousel-commune-color: rgb(26, 23, 27);
  --projects-carousel-street-font: "MuseoSans_500", sans-serif;
  --projects-carousel-street-color: rgb(26, 23, 27);
  --home-page-projects-swiper-button-color: rgb(84, 130, 255);

  /* Home Page - Product Description */
  --product-description-text-font: "MuseoSans_300", sans-serif;
  --product-description-text-color: rgb(84, 130, 255);

  /* Home Page - Our Product */
  --our-product-title-font: "MuseoSans_700", sans-serif;
  --our-product-title-color: rgb(26, 23, 27);
  --our-product-button-text-color: rgb(244, 244, 244);
  --our-product-button-text-font: "QBDKCB-FuturaLT-Book", sans-serif;
  --our-product-button-background-color: rgb(44, 59, 177);
  --our-product-bottom-text-font: "MuseoSans_100", sans-serif;
  --our-product-bottom-text-color: rgb(84, 130, 255);

  /* Home Page - Final Slogan */
  --final-slogan-title-font: "MuseoSans_500", sans-serif;
  --final-slogan-title-color: rgb(44, 59, 177);
  --final-slogan-text-font: "FuturaLT", sans-serif;
  --final-slogan-text-color: rgb(44, 59, 177);

  /* Login Modal */
  --login-modal-background-color: rgb(244, 244, 244);
  --login-modal-overlay-color: rgba(0, 0, 0, 0.7);


  /* Login Modal - Tabs */
  --login-modal-tablist-text-font: "MuseoSans_500", sans-serif;
  --login-modal-tablist-text-color: rgb(244, 244, 244);
  --login-modal-tablist-indicator-color: rgb(44, 59, 177);
  --login-modal-tablist-background-color: rgb(58, 94, 255);

  /* Login Modal - Login Form */
  --login-modal-login-form-button-text-font: "FuturaLT", sans-serif;
  --login-modal-login-form-button-text-color: rgb(244, 244, 244);
  --login-modal-login-form-button-hover-text-color: rgb(244, 244, 244);
  --login-modal-login-form-button-background-color: rgb(58, 94, 255);
  --login-modal-login-form-button-hover-background-color: rgb(44, 59, 177);
  --login-modal-login-form-icon-color: rgb(58, 94, 255);
  --login-modal-login-form-icon-hover-color: rgb(44, 59, 177);
  --login-modal-forgot-password-text-color: rgb(44, 59, 177);

  /* Product Page - Product */
  --product-page-background-color: rgb(244, 244, 244);
  --product-page-info-background-color: rgb(230, 230, 230);
  --product-page-title-font: "FuturaLT", sans-serif;
  --product-page-title-color: rgb(44, 59, 177);
  --product-page-info-text-color: rgb(44, 59, 177);
  --product-page-info-text-font: "FuturaLT", sans-serif;
  --product-page-info-border-radius: 5px;
  --product-page-info-margin: 1rem;
  --product-page-images-margin-mobile: 5px;

  /* Product Page - Filter */
  --product-page-filter-font: "FuturaLT", sans-serif;
  --product-page-filter-font-size: 0.9rem;


  /* Navbar */
  --navbar-text-font: "FuturaLT", sans-serif;
  --navbar-text-color: rgb(26, 23, 27);
  --navbar-background-color: rgb(244, 244, 244);
  --navbar-hover-color: rgba(84, 130, 255, 0.7);
  --navbar-height: 3rem;
  --navbar-padding-top: 0.5rem;
  --navbar-height-mobile: 5rem;

  /* Header */
  --header-text-font: "FuturaLT", sans-serif;
  --header-text-color: rgb(244, 244, 244);
  --header-text-hover-color: rgb(58, 94, 255);
  --header-link-color: rgb(244, 244, 244);
  --header-link-hover-color: rgb(58, 94, 255);
  --header-dropdown-link-color: rgb(244, 244, 244);
  --header-dropdown-link-hover-color: rgb(58, 94, 255);

  /* Body */
  --body-text-font: "FuturaLT", sans-serif;
  --body-text-color: rgb(244, 244, 244);
  --body-text-hover-color: rgb(58, 94, 255);

  /* Footer */
  --footer-background-color: rgb(44, 59, 177);
  --footer-icon-color: rgb(244, 244, 244);
  --footer-icon-hover-color: rgb(58, 94, 255);

  /* Loading Page */
  --loading-page-background-color: rgba(0, 0, 0, 0.834);
  --loading-page-base-background-color: rgb(244, 244, 244);
}

/* Small Phone */
@media only screen and (min-width : 320px) {
  :root {
    /* Global */
    --border-size: 0px;
  }
}

/* Phone */
@media only screen and (min-width : 480px) {
  :root {
    /* Global */
    --border-size: 0px;
  }
}

/* Tablet */
@media only screen and (min-width : 768px) {
  :root {
    /* Global */
    --border-size: 0px;
  }
}

/* Desktop */
@media only screen and (min-width : 992px) {
  :root {
    /* Global */
    --border-size: 0px;
  }
}

/* Large Desktop */
@media only screen and (min-width : 1200px) {
  :root {
    /* Global */
    --border-size: 0px;
  }
}

.App {
  text-align: center;
  background: #020024;
  background: linear-gradient(90deg, #0e0b48 0%, #485eff 35%, #228fa4 100%);
  color: var(--body-text-color);
  font-family: var(--body-text-font);
}
