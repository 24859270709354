.login-modal__register__container {
  width: 80%;
  margin: auto;
  text-align: center;
  color: black;
}

.login-modal__register__button {
  background-color: var(
    --login-modal-login-form-button-background-color
  ) !important;
  color: var(--login-modal-login-form-button-text-color) !important;
  font-family: var(--login-modal-login-form-button-text-font) !important;
  border: none;
  width: 100%;
  border-radius: 20px;
}

.login-modal__register__button:hover {
  background-color: var(
    --login-modal-login-form-button-hover-background-color
  ) !important;
  color: var(--login-modal-login-form-button-hover-text-color) !important;
}
