.login-container {
  height: 50vh;
  width: 33%;
  background-color: aliceblue;
  margin-left: 33vw;
  margin-bottom: 10vh;
  padding-top: 10vh;
  padding-left: 5vw;
  padding-right: 5vw;
  border-radius: 10px;
  text-align: center;
  color: black;
}
.product-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  background-color: yellowgreen;
}
.error-message-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  color: red;
  text-shadow: 1px 1px 2px black;
  background-color: #f49c9c;
  border-radius: 10px;
  margin: 1rem 0;
  border: red 2px solid;
}
