/* Set Page Elements */
.home-page__landing-page__container {
  float: left;
  border: var(--border-size) solid var(--border-color);
  /* position: fixed; */
  width: 100%;
  height: calc(100vh + var(--border-size));
  z-index: 1;
  background-color: var(--landing-page-background-color);
}

.home-page__landing-page__central-content {
  /* position: absolute; */
  margin-top: 25vh;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  width: 100%;
}

.home-page__landing-page__central-content__text h1 {
  color: var(--landing-page-text-color);
  font-family: var(--landing-page-text-font);
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.home-page__landing-page__central-content__button button {
  background-color: var(--landing-page-button-background-color);
  color: var(--landing-page-button-text-color);
  font-size: 1rem;
  font-family: var(--landing-page-button-text-font);
  padding: 1rem 2rem;
  border-radius: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .home-page__landing-page__central-content__text h1 {
    font-size: 4rem;
  }

  .home-page__landing-page__central-content__image img {
    width: 20vw;
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .home-page__landing-page__central-content__text h1 {
    font-size: 6rem;
  }

  .home-page__landing-page__central-content__image img {
    width: 4vw;
    height: auto;
  }
}
