.product-page__product__container {
  height: fit-content;
  width: 100%;
  background-color: var(--product-page-background-color);
  padding-top: 20px;
}

.product-page__product__title {
  font-size: 3rem !important;
  font: var(--product-page-title-font);
  color: var(--product-page-title-color);
  text-align: center;
  margin-bottom: 20px;
}

.product-page__product__subtitle {
  width: 60%;
  font-size: 3rem !important;
  font: var(--product-page-title-font);
  color: var(--product-page-title-color);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 45px;
}

.product-page__product__wrap {
  height: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.product-page__product__images {
  height: 100%;
  width: 45vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .product-page__product__info {
  background-color: brown; */
/* } */

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .product-page__product__wrap {
    flex-direction: column;
  }
  .product-page__product__images,
  .product-page__product__info {
    width: auto;
  }

  .product-page__product__info {
    height: fit-content;
    margin: 5vw 0;
  }

  .product-page__product__wrap {
    width: 90vw;
  }
}

@media only screen and (min-width: 992px) {
  .product-page__product__info {
    height: 40vw;
    width: 40%;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .product-page__product__wrap {
    width: 75%;
  }
}
